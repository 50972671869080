@use "uswds-core" as * with (
  $theme-show-notifications: false,
  $theme-font-path: "../node_modules/@uswds/uswds/dist/fonts",
  $theme-image-path: "../node_modules/@uswds/uswds/dist/img",
  // Theme color base
  $theme-color-base-family: "gray-cool",
  $theme-color-base-lightest: "gray-cool-3",
  $theme-color-base-lighter: "gray-cool-10",
  $theme-color-base-light: "gray-cool-20",
  $theme-color-base: "gray-cool-40",
  $theme-color-base-dark: "gray-cool-70",
  $theme-color-base-darker: "gray-cool-80",
  $theme-color-base-darkest: "gray-warm-90",
  $theme-color-base-ink: "gray-warm-90",
  // Primary colors
  $theme-color-primary-family: "blue-warm",
  $theme-color-primary-lightest: false,
  $theme-color-primary-lighter: "blue-warm-10",
  $theme-color-primary-light: "blue-warm-40v",
  $theme-color-primary: "blue-warm-50v",
  $theme-color-primary-vivid: "blue-warm-50v",
  $theme-color-primary-dark: "blue-warm-60",
  $theme-color-primary-darker: "blue-warm-70v",
  $theme-color-primary-darkest: "blue-warm-80v",

  // Secondary colors
  $theme-color-secondary-family: "red",
  $theme-color-secondary-lightest: false,
  $theme-color-secondary-lighter: "red-10v",
  $theme-color-secondary-light: "red-20",
  $theme-color-secondary: "red-40v",
  $theme-color-secondary-vivid: "red-40v",
  $theme-color-secondary-dark: "red-50",
  $theme-color-secondary-darker: "red-60v",
  $theme-color-secondary-darkest: "red-70",
  // Accent warm colors
  $theme-color-accent-warm-family: "grey-warm",
  $theme-color-accent-warm-lightest: false,
  $theme-color-accent-warm-lighter: false,
  $theme-color-accent-warm-light: "gray-warm-4",
  $theme-color-accent-warm: "gray-warm-10",
  $theme-color-accent-warm-dark: "gray-warm-20",
  $theme-color-accent-warm-darker: "gray-warm-40",
  $theme-color-accent-warm-darkest: "gray-warm-60",
  // Accent cool colors
  $theme-color-accent-cool-family: "cyan",
  $theme-color-accent-cool-lightest: "cyan-5",
  $theme-color-accent-cool-lighter: "cyan-10v",
  $theme-color-accent-cool-light: "cyan-20",
  $theme-color-accent-cool: "cyan-30v",
  $theme-color-accent-cool-dark: "cyan-40v",
  $theme-color-accent-cool-darker: "cyan-50",
  $theme-color-accent-cool-darkest: "cyan-60",
  /*
  ----------------------------------------
  State palette colors
  ----------------------------------------
  */
    // Error colors
  $theme-color-error-family: "red-warm",
  $theme-color-error-lighter: "red-warm-10",
  $theme-color-error-light: "red-warm-30v",
  $theme-color-error: "red-warm-50v",
  $theme-color-error-dark: "red-60v",
  $theme-color-error-darker: "red-70",
  // Warning colors
  $theme-color-warning-family: "gold",
  $theme-color-warning-lighter: "yellow-5",
  $theme-color-warning-light: "yellow-10v",
  $theme-color-warning: "gold-20v",
  $theme-color-warning-dark: "gold-30v",
  $theme-color-warning-darker: "gold-50v",
  // Success colors
  $theme-color-success-family: "green-cool",
  $theme-color-success-lighter: "green-cool-5",
  $theme-color-success-light: "green-cool-20v",
  $theme-color-success: "green-cool-40v",
  $theme-color-success-dark: "green-cool-50",
  $theme-color-success-darker: "green-cool-60",
  // Info colors
  $theme-color-info-family: "cyan",
  $theme-color-info-lighter: "cyan-5",
  $theme-color-info-light: "cyan-20",
  $theme-color-info: "cyan-30v",
  $theme-color-info-dark: "cyan-40v",
  $theme-color-info-darker: "blue-cool-60",
  // Disabled colors
  $theme-color-disabled-family: "gray",
  $theme-color-disabled-light: "gray-10",
  $theme-color-disabled: "gray-20",
  $theme-color-disabled-dark: "gray-30",
  // Emergency colors
  $theme-color-emergency: "red-warm-60v",
  $theme-color-emergency-dark: "red-warm-80",
  /*
  ----------------------------------------
  General colors
  ----------------------------------------
  */
    // Body
  $theme-body-background-color: "white",
  // Text
  $theme-text-color: "ink",
  $theme-text-reverse-color: "white",
  // Links
  $theme-link-color: "primary-dark",
  $theme-link-visited-color: "violet-70v",
  $theme-link-hover-color: "primary-darker",
  $theme-link-active-color: "primary-darker",
  $theme-link-reverse-color: "base-lighter",
  $theme-link-reverse-hover-color: "base-lightest",
  $theme-link-reverse-active-color: "white",
  $theme-respect-user-font-size: true,
  $theme-root-font-size: 10px,
  $theme-global-paragraph-styles: false,
  $theme-global-link-styles: false,
  $theme-global-content-styles: false,
  $theme-typeface-tokens: (
    example-serif-token: (
      display-name: "Example Serif Display Name",
      cap-height: 364px,
    ),
    example-sans-token: (
      display-name: "Example Sans Display Name",
      cap-height: 364px,
    ),
  ),
  $theme-font-type-cond: false,
  $theme-font-type-icon: false,
  $theme-font-type-lang: false,
  $theme-font-type-mono: "roboto-mono",
  $theme-font-type-sans: "source-sans-pro",
  $theme-font-type-serif: "merriweather",
  $theme-font-cond-custom-stack: false,
  $theme-font-icon-custom-stack: false,
  $theme-font-lang-custom-stack: false,
  $theme-font-mono-custom-stack: false,
  $theme-font-sans-custom-stack: false,
  $theme-font-serif-custom-stack: false,
  $theme-font-cond-custom-src: false,
  $theme-font-icon-custom-src: false,
  $theme-font-lang-custom-src: false,
  $theme-font-mono-custom-src: false,
  $theme-font-sans-custom-src: false,
  $theme-font-serif-custom-src: false,
  $theme-font-role-ui: "sans",
  $theme-font-role-heading: "sans",
  $theme-font-role-body: "sans",
  $theme-font-role-code: "mono",
  $theme-font-role-alt: "serif",
  $theme-type-scale-3xs: 2,
  $theme-type-scale-2xs: 3,
  $theme-type-scale-xs: 4,
  $theme-type-scale-sm: 5,
  $theme-type-scale-md: 6,
  $theme-type-scale-lg: 9,
  $theme-type-scale-xl: 12,
  $theme-type-scale-2xl: 14,
  $theme-type-scale-3xl: 15,
  $theme-font-weight-thin: 200,
  $theme-font-weight-light: 300,
  $theme-font-weight-normal: 400,
  $theme-font-weight-medium: 500,
  $theme-font-weight-semibold: 600,
  $theme-font-weight-bold: 700,
  $theme-font-weight-heavy: 800,
  $theme-generate-all-weights: false,
  $theme-body-font-family: "body",
  $theme-body-font-size: "sm",
  $theme-body-line-height: 5,
  $theme-style-body-element: false,
  // Headings
  $theme-h1-font-size: "3xl",
  $theme-h2-font-size: "xl",
  $theme-h3-font-size: "lg",
  $theme-h4-font-size: "md",
  $theme-h5-font-size: "xs",
  $theme-h6-font-size: "3xs",
  $theme-heading-line-height: 2,
  $theme-small-font-size: "2xs",
  $theme-display-font-size: "3xl",
  // Text and prose
  $theme-text-measure-narrow: 1,
  $theme-text-measure: 4,
  $theme-text-measure-wide: 6,
  $theme-prose-font-family: "serif",
  // Lead text
  $theme-lead-font-family: "heading",
  $theme-lead-font-size: "lg",
  $theme-lead-line-height: 6,
  $theme-lead-measure: 6,

  // Banner
  $theme-banner-background-color: "ink"
);

@forward "uswds";

/**
 * Custom theme files below this line...
 */

@forward "theme";
@forward "mermaid";
@forward "helpers";
@forward "footer";

@forward "pages/index";

@import "../node_modules/PrismJS/themes/prism-coldark-dark.css";
