/**
 * Mermaid Styles
 */
.cg-diagrams-hidden {
  position: absolute;
  top: -9999px;
  left: -99999px;
}

.cg-diagrams-stage {
  background: #f1f1f1 !important;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto',
  'Arial', sans-serif !important;
}
.cg-diagrams-stage .label {
  color: #ffffff !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.cg-diagrams,
.cg-diagrams-stage {
  overflow: scroll !important;
  width: 100% !important;
}

.cluster rect {
  fill: #f1f1f1 !important;
  stroke: #345d96 !important;
  stroke-width: 2px !important;
}

.cluster text {
  font-size: 1.7rem !important;
  font-weight: 700 !important;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto',
  'Arial', sans-serif !important;
  font-size: 17px !important;
}

.node rect,
.node circle,
.node ellipse,
.node polygon {
  fill: #2672de !important;
  stroke: #345d96 !important;
  stroke-width: 2px !important;
}

.node .label, .node .nodeLabel {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto',
  'Arial', sans-serif !important;
  color: white !important;
}

.edgePath .path {
  stroke: color('primary-dark') !important;
  stroke-width: 2px !important;
}

.edgePath marker path {
  fill: color('primary-dark') !important;
}

.edgeLabel {
  background-color: #ffffff !important;
  border: #ffffff 5px solid !important;
  color: #000000 !important;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto',
  'Arial', sans-serif !important;
  font-family: 17px !important;
}
.edgeLabel:empty {
  border: none !important;
}

rect {
  fill: #2672de !important;
  stroke: #345d96 !important;
  stroke-width: 2px !important;
}
rect.note {
  fill: #e1f3f8 !important;
  stroke: color('primary')-light !important;
  stroke-width: 2px !important;
}
rect.labelBox {
  fill: color('primary-vivid') !important;
  stroke: color('primary') !important;
}
rect.actor {
  fill: 'priamry-vivid' !important;
  stroke: color('primary-dark') !important;
}

#crosshead path,
#arrowhead path {
  fill: color('primary-dark') !important;
}

line[class^='messageLine'],
line.actor-line {
  fill: color('primary-dark') !important;
  stroke: color('primary-dark') !important;
  stroke-width: 2px !important;
}

line.loopLine {
  fill: color('primary-dark') !important;
  stroke: color('primary') !important;
  stroke-width: 2px !important;
}

text.messageText {
  font-family: 'Bitstream Vera Sans Mono', 'Consolas', 'Courier', monospace !important;
  text-shadow: 2px 2px 0 #ffffff, 1px 1px 0 #5b616b !important;
  fill: color('primary-vivid') !important;
  font-size: 1rem !important;
}

text.actor {
  fill: #ffffff !important;
}

text.noteText {
  fill: #212121 !important;
  font-size: 1.25rem !important;
}

text.labelText {
  font-family: 'Merriweather', 'Georgia', 'Cambria', 'Times New Roman', 'Times',
  serif !important;
  fill: #dce4ef !important;
  font-size: 1.25rem !important;
  text-transform: uppercase !important;
}

text.loopText {
  font-family: 'Merriweather', 'Georgia', 'Cambria', 'Times New Roman', 'Times',
  serif !important;
  font-size: 1rem !important;
  font-style: oblique !important;
}

blockquote .quote {
  font-size: 1.4rem;
  font-style: italic;
}

blockquote .source {
  display: block;
  font-size: 0.9rem;
}

.content-image figcaption {
  font-size: 0.8rem;
}

.custom-story-preview-logo {
  display: flex;
  align-items: center;
}